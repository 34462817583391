import React from 'react'

import Layout from '../components/Layout'

import './donate.scss'

const Team = () => {
    return (
        <Layout
            title="Street art pays tribute to the heroes of Ukraine!"
            body={[
                'The national weather institute of Ukraine (The Ukrainian Hydrometeorological Center or UHMC), asked us to mobilize help in creating a mural in the center of Kyiv. Can they count on your support?',
                'UHMC offers crucial services to those on the frontlines, farmers or other people depending on correct and up to date weather information. the institute would love to express their gratitude and pay tribute to the hardworking people of Ukraine. Dedicating a mural for all the people to see, seems like a great way to do that. It will be painted on a wall of the office of the UHMC, in the heart of Kyiv and will symbolize unity and resilience. The large canvas spans both sides of the wall, covering approximately 231 square meters.',
                'To ensure the success of this project, we have launched a crowdfunding campaign that will cover some minor expenses. We have secured the talented artist Alex Maksiov, pioneer street artist from Ukraine (Kyiv actually!) to create the concept and execute on production. Rest assured, he will not work if safety is not guaranteed.',
                "For more information and a sneak peek at the building's potential, visit the UHMC online media platform.",
            ]}
            simple
        >
            <div className="donate">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/KB3PJ1QoHYs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
                <div className="donate-options">
                    <section>
                        <h3>Donate using Stripe (Ukraine, international)</h3>
                        <stripe-buy-button
                            buy-button-id="buy_btn_1NfH1xBHnswJExfIcAB9dcrO"
                            publishable-key="pk_live_51NfGrOBHnswJExfIpIPL53AwogPSvUNsSKZG77OX0po0SCQ57FQ1BgmI8ytV3pKWN1nyPK2UZHAtyZeKqUxq2YXA00DmZiiPIL"
                        />
                    </section>
                    <section>
                        <h3>Donate using GoFundMe (non-Ukraine)</h3>
                        <a href="https://www.gofundme.com/f/support-the-mural-call-for-kyiv"><img src="https://schof.link/jzsgW0E"/></a>
                    </section>
                </div>
            </div>
        </Layout>
    )
}
export default Team
